import React from 'react';
import styled from 'styled-components';

interface AppProps {
    content?: string;
}

const App = ({ content }: AppProps) => {
    return <Container>{JSON.stringify(content)}</Container>;
};

export default App;

const Container = styled.div``;
